import styled, { keyframes, css } from 'styled-components';
import { TransitionGroup } from 'react-transition-group';
import tokens from '@te-digi/styleguide-tokens';
import { NOTIFICATION_TIMEOUT } from './notifications-controller.js';
import { Box } from '../Box/Box.js';

const StyledTransitionGroup = styled(TransitionGroup).withConfig({
  componentId: "sg1660__sc-dnc5zb-0"
})(["left:50%;list-style-type:none;margin:0;max-width:", ";padding:0;position:fixed;top:2rem;transform:translateX(-50%);width:calc(100% - ", ");z-index:", ";&:empty{display:none;}"], tokens.breakpoint.lg, tokens.space.xl, tokens['z-index'].popover);
const progressAnimation = keyframes(["from{transform:scaleX(0);}to{transform:scaleX(1);}"]);
const StyledNotificationBox = styled(Box).withConfig({
  componentId: "sg1660__sc-dnc5zb-1"
})(["align-items:center;display:flex;min-height:3rem;overflow:hidden;z-index:0;"]);
const StyledIconContainer = styled.div.withConfig({
  componentId: "sg1660__sc-dnc5zb-2"
})(["align-items:center;align-self:stretch;background-color:", ";display:flex;flex:0 0 auto;justify-content:center;position:relative;width:3rem;", ""], props => tokens.color[props.$withProgress ? "".concat(props.$color, "-light") : props.$color], props => props.$withProgress && css(["&::before{animation:", " ", "ms linear;background-color:", ";content:'';height:100%;left:0;position:absolute;top:0;transform-origin:top left;width:100%;}"], progressAnimation, NOTIFICATION_TIMEOUT, tokens.color[props.$color]));
const StyledContent = styled.div.withConfig({
  componentId: "sg1660__sc-dnc5zb-3"
})(["display:flex;align-items:center;flex:1 1 100%;padding:", " ", ";"], tokens.space.xs, tokens.space.md);
const StyledButtons = styled.div.withConfig({
  componentId: "sg1660__sc-dnc5zb-4"
})(["display:flex;padding-right:", ";"], tokens.space.md);

export { StyledButtons, StyledContent, StyledIconContainer, StyledNotificationBox, StyledTransitionGroup };
